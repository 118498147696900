import axios from "axios";

import { ACCESS_TOKEN, REFRESH_TOKEN, ROLE } from "../../lib/consts/consts";
import { User } from "../../lib/models/User";
import { baseURL, http } from "..";

export const auth = async (data: { email: string; password: string }) => {
  const response = await axios.post(`${baseURL}/auth/jwt/create/`, data);
  localStorage.clear();
  localStorage.setItem(ACCESS_TOKEN, response.data.access);
  localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
};

export const me = async () => {
  const response = await http.get<User>("/auth/users/me/");
  if (response.data.type) {
    localStorage.setItem(ROLE, response.data.type);
  } else throw new Error("Ошибка роли");
  return response.data;
};
