import React, { FC } from "react";

type Props = {
  white?: boolean;
  crop?: boolean;
};

export const Logo: FC<Props> = ({ white, crop = false }) => {
  if (crop) {
    return (
      <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 19.993H7.19501V5.99106L0 12.9908V19.993Z" fill={white ? "white" : "#6100FF"} />
        <path d="M14.3928 12.991V5.99121H7.19531L14.3903 12.9934L14.3928 12.991Z" fill={white ? "white" : "#6100FF"} />
        <path d="M21.625 5.99121V19.9932L28.82 12.991V5.99121H21.625Z" fill={white ? "white" : "#6100FF"} />
        <path d="M14.4297 19.9929H21.6247L14.4297 12.9907V19.9929Z" fill={white ? "white" : "#6100FF"} />
      </svg>
    );
  }
  return (
    <svg width="149" height="22" viewBox="0 0 149 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4423_3202)">
        <path
          d="M63.9093 7.35523C62.8715 6.70483 61.6465 6.38086 60.2366 6.38086C58.8267 6.38086 57.6041 6.70483 56.5269 7.35523C55.4522 8.00563 54.6363 8.90637 54.0423 9.98873C53.4852 11.1079 53.1895 12.3351 53.1895 13.6702C53.1895 15.0054 53.4852 16.2694 54.0793 17.3517C54.6733 18.4709 55.4892 19.3373 56.5639 19.9852C57.6386 20.6356 58.8636 20.9596 60.2366 20.9596C61.6095 20.9596 62.8321 20.6356 63.8723 19.9852C64.947 19.3348 65.7628 18.4341 66.3569 17.3517C66.9509 16.2326 67.2467 15.0054 67.2467 13.6702C67.2467 12.3351 66.9509 11.1079 66.3569 9.98873C65.7998 8.90637 64.9839 8.00317 63.9093 7.35523ZM63.0194 17.0621C62.3884 17.9285 61.4986 18.3605 60.35 18.3605C59.2013 18.3605 58.309 17.9285 57.6041 17.0621C56.8991 16.1957 56.5294 15.0766 56.5294 13.7782C56.5294 12.4799 56.8621 11.3239 57.5301 10.4207C58.2351 9.55431 59.0879 9.08553 60.239 9.08553C61.3901 9.08553 62.3169 9.55431 62.9849 10.4575C63.6899 11.3239 64.0226 12.4431 64.0226 13.7782C64.0226 15.1134 63.6899 16.1589 63.0219 17.0621H63.0194Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path
          d="M77.6348 6.74219L73.8882 15.0059L70.0676 6.74219H66.5059L73.9252 21.0706L81.1941 6.74219H77.6324H77.6348Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path
          d="M91.7645 9.08719C91.6166 8.68959 91.2813 8.25763 90.8377 7.86003C90.3545 7.42806 89.7999 7.06728 89.095 6.77767C88.39 6.48805 87.6851 6.3457 86.9062 6.3457C85.5702 6.3457 84.3846 6.63531 83.3814 7.2489C82.3806 7.86248 81.6387 8.72886 81.0816 9.81122C80.5615 10.8936 80.3027 12.1919 80.3027 13.6375C80.3027 15.0831 80.5985 16.4158 81.2295 17.535C81.8605 18.6174 82.6764 19.4838 83.6772 20.0973C84.7149 20.6741 85.829 20.9637 87.0146 20.9637C88.1263 20.9637 89.0925 20.7109 89.9454 20.1341C90.8352 19.5917 91.4292 18.907 91.762 18.1854L91.8359 20.6397H94.8037V6.74085H91.799L91.762 9.08719H91.7645ZM91.5056 13.959C91.5056 15.2574 91.1359 16.3054 90.394 17.135C89.6521 17.9302 88.6883 18.3253 87.6111 18.3253C86.3491 18.3253 85.3853 17.8933 84.6804 17.0981C84.0124 16.2318 83.6796 15.1494 83.6796 13.7774C83.6796 12.4055 84.0124 11.3599 84.7173 10.5303C85.4223 9.66396 86.3491 9.26636 87.6111 9.26636C88.3161 9.26636 88.9841 9.44798 89.5781 9.84313C90.1721 10.2407 90.6528 10.7463 91.025 11.4311C91.3578 12.1158 91.5451 12.911 91.5451 13.7774L91.5081 13.959H91.5056Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path
          d="M108.606 7.31809C107.642 6.70451 106.454 6.38054 105.155 6.38054C104.044 6.38054 103.003 6.67015 102.003 7.24692C101.076 7.78932 100.408 8.47408 100.073 9.30365V0.787109H96.957L97.1049 20.7089H100.147L100.221 18.2546C100.553 18.9762 101.147 19.6266 102 20.2033C102.89 20.7458 103.854 21.0329 104.931 21.0329C106.119 21.0329 107.268 20.7433 108.268 20.1665C109.306 19.5898 110.124 18.7234 110.716 17.641C111.347 16.5587 111.643 15.2579 111.643 13.7804C111.643 12.3028 111.382 11.0364 110.864 9.95405C110.344 8.79805 109.602 7.96849 108.601 7.32055L108.606 7.31809ZM107.344 17.0618C106.639 17.8914 105.675 18.289 104.413 18.289C103.708 18.289 103.04 18.1073 102.446 17.7122C101.852 17.3146 101.372 16.809 100.999 16.1242C100.667 15.4395 100.479 14.6443 100.479 13.7779V13.5963C100.479 12.2979 100.852 11.2499 101.591 10.4572C102.333 9.62762 103.297 9.23002 104.374 9.23002C105.673 9.23002 106.637 9.66198 107.305 10.494C108.01 11.3236 108.342 12.4427 108.342 13.7779C108.342 15.1131 108.01 16.1611 107.342 17.0618H107.344Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path d="M116.471 6.74219H113.205V20.7098H116.471V6.74219Z" fill={white ? "white" : "#6100FF"} />
        <path
          d="M128.708 7.35523C127.67 6.70483 126.445 6.38086 125.035 6.38086C123.625 6.38086 122.403 6.70483 121.326 7.35523C120.251 8.00563 119.435 8.90637 118.841 9.98873C118.284 11.1079 117.988 12.3351 117.988 13.6702C117.988 15.0054 118.284 16.2694 118.878 17.3517C119.47 18.4709 120.288 19.3373 121.363 19.9852C122.44 20.6356 123.662 20.9596 125.035 20.9596C126.408 20.9596 127.631 20.6356 128.671 19.9852C129.746 19.3348 130.562 18.4341 131.156 17.3517C131.747 16.2326 132.046 15.0054 132.046 13.6702C132.046 12.3351 131.75 11.1079 131.156 9.98873C130.599 8.90637 129.783 8.00317 128.708 7.35523ZM127.744 17.0621C127.113 17.9285 126.223 18.3605 125.075 18.3605C123.926 18.3605 123.034 17.9285 122.329 17.0621C121.624 16.1957 121.254 15.0766 121.254 13.7782C121.254 12.4799 121.587 11.3239 122.255 10.4207C122.96 9.55431 123.813 9.08553 124.964 9.08553C126.115 9.08553 127.039 9.55431 127.707 10.4575C128.412 11.3239 128.745 12.4431 128.745 13.7782C128.745 15.1134 128.41 16.1589 127.744 17.0621Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path d="M116.471 0.787109H113.168V4.21581H116.471V0.787109Z" fill={white ? "white" : "#6100FF"} />
        <path
          d="M48.8883 14.7548L39.6523 6.05664V20.7802H42.5092V12.7324L51.7451 21.2146V6.77821H48.9253V14.7548H48.8883Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path
          d="M141.285 12.9135L133.57 6.01924V20.7428H136.649V13.2718L141.285 17.3141L145.959 13.2718V20.7428H149.001V5.98242L141.285 12.911V12.9135Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path d="M0 20.7793H7.19501V6.77734L0 13.7771V20.7793Z" fill={white ? "white" : "#6100FF"} />
        <path
          d="M14.3928 13.7771V6.77734H7.19531L14.3903 13.7796L14.3928 13.7771Z"
          fill={white ? "white" : "#6100FF"}
        />
        <path d="M21.625 6.77734V20.7793L28.82 13.7771V6.77734H21.625Z" fill={white ? "white" : "#6100FF"} />
        <path d="M14.4297 20.7796H21.6247L14.4297 13.7773V20.7796Z" fill={white ? "white" : "#6100FF"} />
      </g>
      <defs>
        <clipPath id="clip0_4423_3202">
          <rect width="149" height="20.4274" fill="white" transform="translate(0 0.787109)" />
        </clipPath>
      </defs>
    </svg>
  );
};
